<app-cmspage-header [headerText]="languageService.f3MeWebApiSimulateHeader" [introText]="languageService.f3MeWebApiSimulateIntro"></app-cmspage-header>

<div class="row simuleren" *ngIf="simulationData">
  <div class="col-sm-12 col-xs-12 col-md-4">
    <div class="switches">
      <app-simultation-inputs [simulationData]="simulationData"></app-simultation-inputs>
    </div>
  </div>

  <div class="col-sm-12 col-xs-12 col-md-8">
    <app-simulate-header [simulationData]="simulationData"></app-simulate-header>
  </div>
</div>

<app-what-if></app-what-if>
