import { RouterModule, Routes } from "@angular/router";
import { DxSelectBoxModule, DxSliderModule } from "devextreme-angular";

import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { canActivateUserAcknowledgementGuard } from "../../../../employee-common/auth/user-acknowledgemen.guard";
import { CmsPageHeaderModule } from "../../../../employee-common/cms/cmspageheaders/cmspage-header.module";
import { WhatIfModule } from "../../../../employee-common/cms/what-if/what-if.module";
import { MenuItemTypesDto } from "../../../../employee-common/models/cms/dto/MenuItemTypes-dto";
import { SimulationRoutesDto } from "../models/dto/SimulationRoutes-dto";
import { SimulateLanguage } from "./language/simulatelanguage.service";
import { SimulateComponent } from "./simulate.component";
import { SimulateService } from "./simulate.service";
import { DXInputSliderComponent } from "./simulation-inputs/simulate-input-slider.component";
import { SimulationInputReferenceComponent } from "./simulation-inputs/simulation-input-reference.component";
import { SliderComponent } from "./simulation-inputs/simultation-inputs.component";
import { SimulateHeaderComponent } from "./simulation-outputs/simulate-header.component";

const routes: Routes = [
  {
    path: SimulationRoutesDto.Simulate,
    component: SimulateComponent,
    data: [{ menuType: MenuItemTypesDto.MainMenu }],
    canActivate: [canActivateUserAcknowledgementGuard],
  },
];

@NgModule({
  declarations: [SimulateComponent, SliderComponent, DXInputSliderComponent, SimulationInputReferenceComponent, SimulateHeaderComponent],
  imports: [RouterModule.forChild(routes), CommonModule, FormsModule, HttpClientModule, CmsPageHeaderModule, DxSliderModule, DxSelectBoxModule, WhatIfModule],
  providers: [SimulateService, SimulateLanguage],
})
export class SimulateModule {}
